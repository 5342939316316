
// import logoMonogram from '../assets/svg/monogram.svg';
// import logoVertical from '../assets/svg/logo-vertical.svg';
import React from 'react';
import {Link} from 'react-router-dom';





function FooterPr(){
    return (
     <div className="footer_pr">
    
       <footer id="footer_pr">
                    <div className="footer_pr-wrap">
                    
                       <div className="f-follow-sect">
                    <div className="f-follow-wrap">
                        <div className="f-about f-work"><Link to="/project">Work</Link></div>
                        <div className="f-about f-ab"><Link to="/about">About</Link></div>
                        <div className="ab-line"></div>
                        
                    </div>
                </div> 
                       
                    </div> 

                </footer>


              


     </div> /* ends */



    )
}


export default FooterPr;
import React from 'react';
import './App.scss';
import { Routes, Route, useLocation} from "react-router-dom";


import { AnimatePresence } from 'framer-motion';
import Header from './components/header';
import HeaderPr from './components/header_pr';
import FooterPr from './components/footer_pr';
// import GoToTop from './components/scrollToTop';


// Main
const Home = React.lazy(() => import('./pages/home'));
const Project = React.lazy(() => import('./pages/project'));
const About = React.lazy(() => import('./pages/about'));
const Contact = React.lazy(() => import('./pages/contact'));


// import Home from './pages/home';
// import Project from './pages/project';
// import About from './pages/about';
// import Contact from './pages/contact';






// Project lists
// Shoot
// import Bata from './pages/projects/shoot/bata';


const BKAM = React.lazy(() => import('./pages/projects/shoot/bkam'));
const Burgrill = React.lazy(() => import('./pages/projects/shoot/burgrill'));
const August = React.lazy(() => import('./pages/projects/shoot/august'));
const Anglow = React.lazy(() => import('./pages/projects/shoot/anglow'));
const FaceRituals = React.lazy(() => import('./pages/projects/shoot/face_rituals'));
const RosvinBugs = React.lazy(() => import('./pages/projects/shoot/rosvin_bugs'));
const JuicyChemistry = React.lazy(() => import('./pages/projects/shoot/juicy_chemistry'));
const NamasteAsia = React.lazy(() => import('./pages/projects/shoot/namaste_asia'));
const MoolOrganic = React.lazy(() => import('./pages/projects/shoot/mool_organic'));
const AmazingVodka = React.lazy(() => import('./pages/projects/shoot/amazing_vodka'));
const Wow = React.lazy(() => import('./pages/projects/shoot/wow'));
const SaintG = React.lazy(() => import('./pages/projects/shoot/saint_g'));
const BodyCupid = React.lazy(() => import('./pages/projects/shoot/body_cupid'));
const Lqi = React.lazy(() => import('./pages/projects/shoot/lqi'));
const RossoBrunello = React.lazy(() => import('./pages/projects/shoot/rosso_brunello'));
const DragonWagon = React.lazy(() => import('./pages/projects/shoot/dragon_wagon'));
const Poet = React.lazy(() => import('./pages/projects/shoot/poet'));
const Gaia = React.lazy(() => import('./pages/projects/shoot/gaia'));
const BellaVitaOrganic = React.lazy(() => import('./pages/projects/shoot/bella_vita_organic'));
const Skinella = React.lazy(() => import('./pages/projects/shoot/skinella'));


// import BKAM from './pages/projects/shoot/bkam';
// import Burgrill from './pages/projects/shoot/burgrill';
// import August from './pages/projects/shoot/august';
// import Anglow from './pages/projects/shoot/anglow';
// import FaceRituals from './pages/projects/shoot/face_rituals';
// import RosvinBugs from './pages/projects/shoot/rosvin_bugs';
// import JuicyChemistry from './pages/projects/shoot/juicy_chemistry';
// import NamasteAsia from './pages/projects/shoot/namaste_asia';
// import MoolOrganic from './pages/projects/shoot/mool_organic';
// import AmazingVodka from './pages/projects/shoot/amazing_vodka';
// import Wow from './pages/projects/shoot/wow';
// import SaintG from './pages/projects/shoot/saint_g';
// import BodyCupid from './pages/projects/shoot/body_cupid';
// import Lqi from './pages/projects/shoot/lqi';
// import RossoBrunello from './pages/projects/shoot/rosso_brunello';
// import DragonWagon from './pages/projects/shoot/dragon_wagon';

// Film
// import OaksmithFilm from './pages/projects/film/oaksmith_vd';
// import DaMilanoFilm from './pages/projects/film/da_milano_vd';


// Main App



function Menu(){

  const location = useLocation().pathname;
  
  if(location === "/"){
    return false;
  }else if(location === "/about"){
    return <Header />;
  }else if(location === "/project"){
    return <Header />;
  }
  else if(location === "/exp"){
    return false;
  }
  else{
    return <HeaderPr />
  }


}


function FooterSection(){

  const location = useLocation().pathname;
  
  if(location === "/"){
    return false;
  }else if(location === "/about"){
    return false;
  }else if(location === "/project"){
    return false;
  }
  else if(location === "/exp"){
    return false;
  }
  else{
    return <FooterPr />
  }


}







function App() {

  const location = useLocation();


  return (

    <>

      <FooterSection />  
      <Menu />
      <React.Suspense fallback={<span></span>}>
      <AnimatePresence exitBeforeEnter initial={true}>
      <Routes location={location} key={location.pathname}>
        {/* Main */}
        <Route exact path="/" element={<Home />}></Route> 
        <Route path="/about" element={<About />}></Route>
        <Route path="/project" element={<Project />}></Route>
        <Route path="/contact" element={<Contact />}></Route>



        {/* Project Shoot */}
        <Route path="/shoot/bkam" element={<BKAM />}></Route>
        <Route path="/shoot/poet" element={<Poet />}></Route>
        <Route path="/shoot/gaia" element={<Gaia />}></Route>
        <Route path="/shoot/skinella" element={<Skinella />}></Route>
        <Route path="/shoot/bella_vita_organic" element={<BellaVitaOrganic />}></Route>
        <Route path="/shoot/burgrill" element={<Burgrill />}></Route>
        <Route path="/shoot/amazing_vodka" element={<AmazingVodka />}></Route>
        <Route path="/shoot/anglow" element={<Anglow />}></Route>
        <Route path="/shoot/wow" element={<Wow />}></Route>
        <Route path="/shoot/face_rituals" element={<FaceRituals />}></Route>
        <Route path="/shoot/rosvin_bugs" element={<RosvinBugs />}></Route>
        <Route path="/shoot/namaste_asia" element={<NamasteAsia />}></Route>
        <Route path="/shoot/mool_organic" element={<MoolOrganic />}></Route>
        <Route path="/shoot/juicy_chemistry" element={<JuicyChemistry />}></Route>
        <Route path="/shoot/august" element={<August />}></Route>
        <Route path="/shoot/saint_g" element={<SaintG />}></Route>   
        <Route path="/shoot/body_cupid" element={<BodyCupid />}></Route>   
        <Route path="/shoot/lqi" element={< Lqi/>}></Route>   
        <Route path="/shoot/rosso_brunello" element={< RossoBrunello/>}></Route>   
        <Route path="/shoot/dragon_wagon" element={< DragonWagon/>}></Route>   

      </Routes>
      </AnimatePresence>
      </React.Suspense>  
</>
  );
}





export default App;

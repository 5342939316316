
// import logoMonogram from '../assets/svg/monogram.svg';
// import logoVertical from '../assets/svg/logo-vertical.svg';
import {motion} from 'framer-motion';
import React from 'react';
import {Link} from 'react-router-dom';



function Header(){
    return (
     <div className="header">

       <header id="header">
                    <motion.div
                    initial={{opacity: 0}} 
                    animate={{ opacity: [0,1] }}
                    exit={{opacity: 1}}
                    transition={{ duration: 2 }}
                    
                    className="header-wrap">
                    
                        <div className="logo-sect">
                            <div className="logo">
                            
                            <Link to="/">
                                    <picture>   
                                        <source media="(max-width:767px)" srcSet="assets/svg/logo-vertical.svg"/>
        
                                        <img src="/assets/svg/logo-vertical.svg" alt="" />
                                </picture> 
                                </Link>
                                
                            </div>
                        </div> 
        
                       
                    </motion.div> 

                </header>


              


     </div> /* ends */



    )
}


export default Header;
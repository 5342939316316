
// import logoMonogram from '../assets/svg/monogram.svg';
// import logoVertical from '../assets/svg/logo-vertical.svg';
import {motion} from 'framer-motion';
import React from 'react';
import {Link} from 'react-router-dom';



function HeaderPr(){
    return (
     <div className="header_pr">

<header id="header_pr">
                    <motion.div
                    initial={{opacity: 0}} 
                    animate={{ opacity: [0,1] }}
                    exit={{opacity: 1}}
                    transition={{ duration: 2 }}
                    
                    className="header_pr-wrap">
                    
                        <div className="logo-sect">
                            <div className="logo">
                            
                            <Link to="/">
                                    <picture>   
        
                                        <img src="/assets/svg/logo-vertical-white.svg" alt="" />
                                </picture> 
                                </Link>
                                
                            </div>
                        </div>                
                    </motion.div> 

                </header>






              


     </div> /* ends */






    )
}


export default HeaderPr;